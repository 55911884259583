<template>
  <div>
    <h2>OvO</h2>
    <div class="card">
      <p><a href="https://steamcommunity.com/sharedfiles/filedetails/?id=2189395411">One Versus One</a>, or OvO for short, was a Gmod gamemode me and my brother worked on during covid lockdown to blow off some steam. We didn't spend long on it, and it ended up abandoned once we returned to normal(ish) lives.</p>
      <p>With the development of S&Box, we intend to revive the project properly, making a fast pace PvP environment, mainly designed for one on one environments where you can just chill, shoot the shit with a mate, and honestly have an all around good time.</p>
      <p>During development, we introduced some daft bugs, that we later reworked into features. One of which was the <code>Spectre Ability</code>, which allowed you to borrow power from the afterlife granting you temporary immunity and increased speed, in exchange you give up the ability to use your weapons while in this ability and your health is slowly drained.</p>
      <p>You can <a href="https://forum.facepunch.com/t/245099">Follow development here</a>, or keep an eye on my <a href="https://twitter.com/WhiskeeDev">twitter</a>.</p>
      <div
        v-for="(media, index) in getMedia('wsky_ovo_remake')"
        :key="media.src + index"
        :class="index % 2 ? 'even' : 'odd'"
        style="display: inline"
      >
        <a v-if="media.type === 'image'" href="#" @click="previewSrc = media.src">
          <img :src="media.src">
        </a>
      </div>
    </div>
    <h2>Together</h2>
    <div class="card">
      <p>Remember <a href="https://gmtower.org">GMTower</a>? Yeah that shit was great... we're gonna recreate that in S&Box.</p>
      <p>So few things out of the way - Yes we are aware of Tower Unite, we actually play it, we just want to create the original vibe of GMTower in S&Box, utilizing <a href="https://sbox.facepunch.com/news/june2021#BespokeNetworking">Bespoke Networking</a>. Our intention is to host a API via Jaxbot (another one of our projects) which will give us the ability to do stuff not normally possible in Gmod.</p>
      <p>To begin with, Together will be only available to Patreons of TopHat Network™ and will be limited access.</p>
      <p>To be extremely specific: <strong>WE ARE NOT COMPLETELY RECREATING GMTOWER OR TOWER UNITE</strong>. We are creating our own version, specific for TopHat Network™</p>
    </div>
    <h2>SCP:RP</h2>
    <div class="card">
      <p>Originally, this was being created in Garry's Mod and a lot of the development had already been done.</p>
      <p>However, given the amount of progress S&Box development has made, we decided to postpone any further development on Gmod.</p>
      <p>Given that anything we create in Gmod, would need to be recreated again. Rather than doing that, we'll just create everything using the new technologies available to use by Source 2 and S&Box.</p>
    </div>
    <image-preview v-model="previewSrc" />
  </div>
</template>
<script>
import ImagePreview from '@/components/ImagePreview'

export default {
  components: { ImagePreview },
  data () {
    return {
      previewSrc: null,
      imageSections: {
        'wsky_ovo_remake': {
          count: 7,
          folder: 'wsky_ovo_remake'
        }
      }
    }
  },
  methods: {
    getMedia (id) {
      if (!id) return null

      let media = []
      for (let imageId = 1; imageId <= this.imageSections[id].count; imageId++) {
        // console.log(`./assets/images/${this.imageSections[id].folder}/${imageId}.jpg`)
        media.push({
          type: 'image',
          src: require(`@/assets/images/${this.imageSections[id].folder}/${imageId}.jpg`)
        })
      }

      if (this.imageSections[id].videos) {
        this.imageSections[id].videos.forEach(videoId => {
          media.push({
            type: 'video',
            src: require(`@/assets/images/${this.imageSections[id].folder}/${videoId}.mp4`),
            thumb: require(`@/assets/images/${this.imageSections[id].folder}/${videoId}.jpg`)
          })
        })
      }

      return media
    }
  }
}
</script>
